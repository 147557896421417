import React from "react";
import { Container, LinearProgress } from "@mui/material";
import {
  createBrowserRouter,
  RouterProvider,
  Navigate,
  useLocation,
  Outlet,
} from "react-router-dom";
import { Footer } from "./controls/Footer";
import { HomePage } from "./pages/Home/HomePage";
import { ListingsPage } from "./pages/Listing/ListingsPage";
import { RealmAppProvider, useRealmApp } from "../components/RealmApp";
import { ThemeProvider } from "../components/Theme";
import Config from "../shared/Config";
import "./App.css";
import AuthRedirect from "./pages/AuthRedirect";
import { ItemCardDetail } from "./pages/Item/ItemCardDetail";
import { Login } from "./pages/Login";
import { ProfilePage } from "./pages/Profile/ProfilePage";
import { RedirectPage } from "./pages/RedirectPage";
import { CategoryPage } from "./pages/CategoryPage";
import { AppTopBar } from "./controls/AppTopBar";
import { useStoreSettings } from "../hooks/useStoreSettings";
import { LoginDialog } from "./controls/LoginDialog";
import { ResetPasswordPage } from "./pages/ResetPasswordPage";
import { EmailConfirmPage } from "./pages/EmailConfirmPage";
import { OrdersPage } from "./pages/OrdersPage";
import AppDrawer from "./controls/AppDrawer";
import { Sales } from "./pages/Sales";
import Export from "./pages/Export";
import { SellerPage } from "./pages/Seller";
import { EditItem } from "./pages/Listing/EditItem";
import ChatPage from "../modules/Chat/Chat";
import Conversation from "../modules/Chat/Conversation";
import { Register } from "./pages/Register";
import { CategoriesPage } from "./pages/CategoriesPage";
import { SellersPage } from "./pages/Sellers";
import { SellerReports } from "./pages/Reports/SellerReports";
// import { LinkAccount } from "./pages/LinkAccount";
import Debug from "debug";
import { SearchDialog } from "./controls/SearchDialog";
import { Page } from "../modules/ContentPages/Page";
import { SocialPosts } from "../modules/ContentPages/SocialPosts";
import { SalePage } from "./pages/SalePage";
import { PayoutReport } from "./pages/Reports/PayoutReport";
import { SellerMonthReport } from "./pages/Reports/SellerMonthReport";
const debug = Debug("App");

const { appId } = Config();

function RequireAuth({ children }) {
  const { currentUser } = useRealmApp();
  const location = useLocation();
  if (currentUser?.isLoggedIn === true) {
    return children
  } else {
    return <Navigate to={`/login?redirect=${location.pathname}`} replace />;
  }
}

export default function AppWithRealm() {
  return (
    <RealmAppProvider appId={appId}>
      {/* <PayPalScriptProvider deferLoading={false} options={initialOptions}> */}
      <ThemeProvider>
        <App />
      </ThemeProvider>
      {/* </PayPalScriptProvider> */}
    </RealmAppProvider>
  );
}

function Layout({ store, loading, setShowLogin, showLogin, showSearch, setShowSearch }) {
  const { currentUser, logOut } = useRealmApp();
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  
  return (
    <div className="App">
      <AppTopBar setDrawerOpen={setDrawerOpen} store={store} currentUser={currentUser} setShowLogin={setShowLogin} />
      <AppDrawer
        store={store}
        currentUser={currentUser}
        setDrawerOpen={setDrawerOpen}
        drawerOpen={drawerOpen}
        setShowLogin={setShowLogin}
        setShowSearch={setShowSearch}
        logOut={logOut} />
      {loading ? <LinearProgress /> :
        <Container id="app-container" disableGutters={true}>
          <LoginDialog open={showLogin} store={store} setShowLogin={setShowLogin} />
          <SearchDialog store={store} open={showSearch} setShowSearch={setShowSearch} />
            <Outlet />
          <Footer />
        </Container>}
    </div>
  );
}

/**
 * App Component
 * @returns {JSX.Element}
 */
function App() {
  const { currentUser } = useRealmApp();
  const { store, loading } = useStoreSettings(); // use currentUser to force refresh when logged in
  const [showLogin, setShowLogin] = React.useState(false);
  const [showSearch, setShowSearch] = React.useState(false);
  
  React.useMemo(() => {
    debug('currentUser:', {currentUser});
  },[currentUser])

  if (loading) {
    return;
  }
  const router = createBrowserRouter([
    {
      id:'root',
      path: '/', 
      element: <Layout store={store} loading={loading} setShowLogin={setShowLogin} showLogin={showLogin} setShowSearch={setShowSearch} showSearch={showSearch}  />, //<Root store={store} loading={loading} showLogin={showLogin} setShowLogin={setShowLogin} />,
      children: [
        { index: true, element: <HomePage store={store} setShowSearch={setShowSearch} /> },
        { path: "items/:itemId", element: <ItemCardDetail setShowLogin={setShowLogin} store={store} /> },
        { path: "sale", element: <SalePage store={store} /> },
        { path: "category/:category", element: <CategoryPage store={store} /> },
        { path: "page/:slug", element: <Page store={store} /> },
        { path: "/sellers", element: <RequireAuth><SellersPage store={store} /></RequireAuth>},
        { path: "/redirect", element: <RedirectPage />},
        { path: "/updates", element: <SocialPosts />},
        { path: "/login", element: <Login store={store} setShowSearch={setShowSearch} />},
        { path: "/auth/callback", element: <AuthRedirect />},
        { path: "/resetPassword", element: <ResetPasswordPage />},
        { path: "/signup", element: <Register setShowLogin={setShowLogin} />},

        // { path: "/link-account", element: <LinkAccount setShowLogin={setShowLogin} />},
        { path: "/confirm", element: <EmailConfirmPage />},
        { path: "/categories", element: <CategoriesPage store={store} />},
        { path: "/seller/:userId", element: <RequireAuth><SellerPage store={store} /></RequireAuth>},
        { path: "/listings", element: <RequireAuth><ListingsPage store={store} activeListings={true} currentUser={currentUser} /></RequireAuth>},
        { path: "/listings-inactive", element: <RequireAuth><ListingsPage store={store} currentUser={currentUser} /></RequireAuth>},
        { path: "/edititem/:itemId", element: <RequireAuth><EditItem store={store} /></RequireAuth>},
        { path: "/orders", element: <RequireAuth><OrdersPage currentUser={currentUser} /></RequireAuth>},
        { path: "/sales", element: <RequireAuth><Sales store={store} currentUser={currentUser} /></RequireAuth>},
        { path: "/reports/seller", element: <RequireAuth><SellerReports /></RequireAuth>},
        { path: "/reports/seller/month/:yearMonth", element: <RequireAuth><SellerMonthReport /></RequireAuth>},
        { path: "/reports/seller/payouts", element: <RequireAuth><PayoutReport /></RequireAuth>},
        { path: "/profile", element: <RequireAuth><ProfilePage store={store} currentUser={currentUser} /></RequireAuth>},
        { path: "/exports", element: <RequireAuth><Export /></RequireAuth>},
        { path: "/chat", element: <RequireAuth><ChatPage /></RequireAuth>},
        { path: "/chat/c/:conversationId", element: <RequireAuth><Conversation isDialog={undefined} chat={undefined} /></RequireAuth>},
        // { path: "*", element: <Root /> },
      ]
    }
  ]);

  return <RouterProvider router={router} />;
}
