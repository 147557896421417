import React from "react";
import { Container, Breadcrumbs, Link, Divider } from "@mui/material";
import { AccountBox, HelpCenter, Home } from "@mui/icons-material";
import { useRealmApp } from "../RealmApp";
import { Link as RLink } from "react-router-dom";

export function Footer() {
  return (
      <MoreInfoTemplateAndDocs />
  );
}

function MoreInfoItem({ children }) {
  return (
  <>
    <Divider style={{ marginTop: 9 }} light></Divider>
    <Container style={{ textAlign: "center", padding: "14px 0px 150px", marginTop: "auto" }}>
      {children}
    </Container>
  </>
  
  );
}

export function MoreInfoTemplateAndDocs() {
  const { currentUser } = useRealmApp();
  return (
    <MoreInfoItem>
      <Breadcrumbs aria-label="breadcrumb">
        <Link component={RLink} to={"/"}><Home /></Link>
        <Link component={RLink} to={"/page/about"}><HelpCenter /></Link>
        {currentUser && <Link component={RLink} to={"/profile"}><AccountBox /></Link>}
      </Breadcrumbs>
      <Divider style={{margin:8}} light></Divider>
      <span>Site powered by <Link target="_blank" href="https://www.swag-shops.com/">Swagshops</Link></span>
    </MoreInfoItem>
  );
}