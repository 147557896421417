import React from "react";
import {
  Card,
  CardMedia,
  CardContent,
  Typography,
  Link
} from "@mui/material";
import { itemImageURL } from "../../shared/helpers";
import { Link as RLink } from "react-router-dom";

export function SearchItemCard({ item, setShowSearch }) {

  return (
    <Card style={{ maxHeight: 320 }}>
      <Link component={RLink} to={`/items/${item._id}`} underline="none"
      onClick={() => setShowSearch(false)}
      >
        <CardMedia
          component="img"
          style={{ maxHeight: 240 }}
          image={itemImageURL(item.imageUrl)}
          title={"Item_" + item._id}
        />
        <CardContent>
          {item.title} {"$" + item.price}
          <Typography variant="body2" color="textSecondary" component="p">
            {item.category}{item.size && ", Size:" + item.size}
          </Typography>
        </CardContent>
      </Link>
    </Card>
  );
}
