import React, { useEffect } from "react";
import { Link as RLink, ScrollRestoration, useParams } from 'react-router-dom'
import { Card, CardContent, Typography, Container, ImageList, ImageListItem, Avatar, Rating, List, ListItem, ListItemAvatar, ListItemText } from "@mui/material";
import { LinearProgress, Button, CardHeader, Chip, Divider, IconButton } from "@mui/material";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import { ChevronRight, Edit } from "@mui/icons-material";
import ItemImage from "../../controls/ItemImage";
import CheckoutButtons from "./CheckoutButtons";
import { Box } from "@mui/system";
import { avatarImageURL, itemConditions, itemImageUrls, trackEvent } from "../../../shared/helpers";
import { useAxiosEndpoint } from "../../../hooks/useAxiosEndpoint";
import DeliveryOptions from "./DeliveryOptions";
import Debug from "debug";
import { useRealmApp } from "../../RealmApp";
import { ErrorPage } from "../ErrorPage";
const debug = Debug("pages:ItemCardDetail");

const paypalDefaults = {
  // debug: true,
  clientId: null,
  // "client-id": "AbKFuDuweofYwiS-CIf9gvaGU2YPcPww4YTSIH4VgjFkTgLcL7BFsDbl2kiN7ZScpXyfBmx4e0qHEdLl", // Live Partner 
  // "client-id": "AcfqWFxCbedlvRMHKLaOoVRv5O_87NX1BgoF4IeLHhig5sxlX7-uVHpvwHpErkGaR6fWjMkwWUtjKBX7", // Sandbox
  // "client-id": "Abez7Yomie1-ca1oXWPxUH_2MM_kiCOLqJUGKvZKtRuBQ1bPQOr6e6--P0mULinYqNguo34VqlU5D6mA", // Sandbox Partner
  "data-partner-attribution-id": "SWAGSHOPS_SP_PPCP",
  currency: "USD",
  intent: "capture",
  components: "buttons,funding-eligibility", // applepay | marks
  "enable-funding": "venmo",
  "disable-funding": "paylater,credit",
  "merchant-id": "ZZZZZ"
  // "buyer-country":"US",
  // "data-client-token": "",
};

export function ItemCardDetail({ setShowLogin, store }) {
  const { currentUser } = useRealmApp()
  // const randomNum = Math.round(Math.random() * 10);
  // const [favCount, setFavCount] = React.useState(randomNum);
  const { itemId } = useParams();
  const queryParams = { itemId };
  const { data, loading, error } = useAxiosEndpoint('getListing', { queryParams, useCache: true });
  /** @type {useState<Listing>} */
  const [item, setItem] = React.useState();
  const [paypalOptions, setPaypalOpts] = React.useState({ ...paypalDefaults });

  useEffect(() => {
    const _item = data?.documents[0]
    if (_item) {
      const merchantId = _item?.seller[0].merchantId || ''
      setItem(_item);
      setPaypalOpts(prev => ({
        ...prev,
        'merchant-id': merchantId,
        clientId: store.paypalClientId[store.paypalDeveloperMode]
      }))
      debug('item:', _item, 'merchant-id:', merchantId);
      document.title = `${_item.title} - ${store.name}`;
      trackEvent({ action: 'view_item' });
    }
  }, [data, store])

  /**
   * @param {string} code
   */
  function getCondition(code) {
    const condition = itemConditions.find(i => i.code === code);
    return condition?.name;
  }

  if (error) {
    return <ErrorPage error={error} />
  }

  return (
    <Container maxWidth="sm" className="main-container">
      {loading ? <LinearProgress /> :
        <>
          {item?.seller ?
            <PayPalScriptProvider deferLoading={false} options={paypalOptions}>
              <Card style={{ maxWidth: 540, marginTop: 12 }}>
                <CardHeader style={{ paddingTop: 2, paddingBottom: 2 }}
                  title={store.paypalDeveloperMode === 'sandbox' ? <Typography color="orange">Sandbox Mode</Typography> : ''}
                />
                <ImageList
                  sx={{
                    gridAutoFlow: "column",
                    gridTemplateColumns: "repeat(auto-fill,minmax(85%,1fr)) !important",
                    gridAutoColumns: "minmax(85%, 1fr)"
                  }}>
                  {itemImageUrls(item).map((url, idx) => (
                    <ImageListItem key={idx}>
                      <ItemImage height={540} loading={idx > 1 ? "lazy" : "eager"} url={url} large={true} />
                    </ImageListItem>
                  ))}
                </ImageList>
                <CardContent>
                  {/* <div style={{ margin: 'auto', float: 'right' }}>
                    <Badge sx={{ ml: 1 }} badgeContent={favCount} overlap="circular" color="info">
                      <IconButton onClick={() => setFavCount(p => p + 1)} size="medium">
                        <Favorite color="error" />
                      </IconButton>
                    </Badge>
                  </div> */}
                  <Typography variant="h5" color="" component="h4">
                    {item.title}
                    {item.seller[0]?.id === currentUser?.id ?
                      <Button sx={{ ml: 1 }} variant="outlined" startIcon={<Edit />} component={RLink} to={`/edititem/${itemId}`}>Edit</Button>
                      : ""}
                  </Typography>

                  <Divider sx={{ mt: 1, mb: 2 }} />

                  <Box sx={{ float: 'right' }}>
                    <Chip clickable
                      sx={{ mr: 1, color: 'blue', textTransform: "capitalize" }}
                      component={RLink} to={`/category/${item.category}`}
                      label={item.category} variant="outlined"
                    />
                    {item.category2 ? <Chip clickable
                      sx={{ float: 'right', color: 'blue', textTransform: "capitalize" }}
                      component={RLink} to={`/category/${item.category2}`}
                      label={item.category2} variant="outlined"
                    /> : ""}
                  </Box>

                  <Typography sx={{ ml: 1, mt: 1 }} variant="body2" color="textSecondary" component="p">
                    {item.condition && item.condition !== 'na' ?
                      <span>Condition: {getCondition(item.condition) || ""}</span> : ""
                    }
                  </Typography>

                  <Box sx={{ display: 'flex', ml: 1, mt: 1 }} color="">
                    {item.applyDiscount ?
                      <>
                        <Typography variant="h5" color={"error"} sx={{ mr: 2 }}>
                          {item.discountPrice % 1 ? "$" + item.discountPrice.toFixed(2) : "$" + item.discountPrice}
                        </Typography>
                        <Typography sx={{ mr:1, textDecoration: "line-through" }} variant="h5">
                          {item.price % 1 ? "$" + item.price.toFixed(2) : "$" + item.price}
                        </Typography>
                        <Typography variant={"subtitle1"}>
                          {item.discountRate}% Off
                        </Typography>
                      </>
                      :
                      <>
                        <Typography variant="h5">
                          {item.price % 1 ? "$" + item.price.toFixed(2) : "$" + item.price}
                        </Typography>
                        {item.retailPrice > item.price ?
                          <Typography variant="h6" component={"span"} sx={{ ml: 2, textDecoration: "line-through" }} color="grey">
                            {item.retailPrice % 1 ? "$" + item.retailPrice.toFixed(2) : "$" + item.retailPrice}
                          </Typography> : ""}
                      </>
                    }
                  </Box>

                  <Typography sx={{ ml: 1, mt: 1, textTransform: "capitalize" }} color="" component="p">
                    {item.size !== 'na' && item.size !== undefined ? " Size: " + item.size : ''}
                  </Typography>

                  <Typography sx={{ ml: 1, mt: 2 }} variant="h4" component="h5">
                    <DeliveryOptions item={item} store={store} />
                  </Typography>
                  {['sold', 'archived'].includes(item.status) ?  <Button color="error" size={"large"}>{item.status}</Button> : <></>}
                </CardContent>
                {item.status === 'active' &&
                  <>
                    {currentUser && item.seller[0]?.sellingEnabled ?
                      <>
                        <CheckoutButtons currentUser={currentUser} item={item} setItem={setItem} />
                      </> :
                      <Box sx={{ px:2, pb:1 }}>
                        {currentUser ?
                          <Chip color="error" label={"Sales disabled for this Seller"} />
                          :
                          <Button 
                            fullWidth={true} 
                            variant="contained" 
                            color="primary" 
                            onClick={() => setShowLogin(true)}>
                            Buy Now
                          </Button>
                        }
                      </Box>}
                  </>}
                <Typography sx={{ ml: 1, mt: 1 }} variant={"body1"} color="textSecondary" component="p">
                  {item.description}
                </Typography>
                {item.inventoryCount > 1 ? <Typography sx={{ ml: 1, mt: 1 }} variant="body2" component="p">
                  In-stock: {item.inventoryCount}
                </Typography> : ''}
                {item.salesTaxRate > 1 ? <Typography sx={{ ml: 1, mt: 1 }} variant="body2" component="p">
                  Estimate Tax: ${((item.salesTaxRate/100) * item.price).toFixed(2)}
                </Typography> : ''}
                {item.seller &&
                  <Box sx={{ m: 2 }} >
                    <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                      <ListItem sx={{ border: '1px solid #ccc' }} divider={true} component={RLink} to={`/seller/${item.seller[0]?.id}`}
                        secondaryAction={
                          <IconButton edge="end" aria-label="comments">
                            <ChevronRight />
                          </IconButton>
                        }
                      >
                        <ListItemAvatar>
                          <Avatar imgProps={{ loading:"lazy" }} src={avatarImageURL(item.seller[0]?.imageUrl)}>
                            {item.seller[0]?.fullname[0][0]}
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          primary={<>Seller: {item.seller[0]?.fullname}</>}
                          secondary={
                            <Rating name="read-only" value={4.5} readOnly precision={0.5} />
                          } />
                      </ListItem>
                    </List>
                  </Box>}
              </Card>
            </PayPalScriptProvider> : <ErrorPage error={error} />}
        </>}
        <ScrollRestoration />
    </Container>
  );
}
