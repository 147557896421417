import React from "react";
import * as Realm from "realm-web";
import Debug from "debug";
import { useCollection } from "./useCollection";
import { useRealmApp } from "../components/RealmApp";
import Config from "../shared/Config";
const { dataSourceName } = Config();
const debug = Debug("hooks:useUsers");


export function useUsers() {
  const realmApp = useRealmApp();
  const { currentUser } = useRealmApp();
  const [userSaved, setUserSaved] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [user, setUser] = React.useState({});
  const { providerType } = currentUser
  const anonUser = (providerType === 'anon-user');

  const collection = useCollection({
    cluster: dataSourceName,
    db: "ecommdb",
    collection: "users"
  });

  React.useEffect(() => {
    if (anonUser) {
      setUser({fullname:'Guest User'})
      setLoading(false);
      return;
    }
    collection?.aggregate([
      {
        $lookup:
        {
          from: "stores",
          localField: "id",
          foreignField: "admins",
          as: "stores"
        }
      },
      {
        $lookup:
        {
          from: "invites",
          localField: "email",
          foreignField: "email",
          as: "invites"
        }
      }]).then((res) => {
        const [user] = res
        debug('fetch:mongo', { user });
        setUser(user);
        setLoading(false);
      });
  }, [collection, anonUser]);

  const upsertUser = async (profile) => {
    profile.updated_ts = new Date();

    const res = await collection.updateOne(
      { id: realmApp.currentUser.id },
      { $set: profile },
      { upsert: true } // not needed, should create profile via newUserHandler
    );
    debug('upsertUser:', res);
    const user = await collection.findOne({ id: realmApp.currentUser.id })
    setUserSaved(true);
    setUser(user);
  };

  // build and insert an order
  const insertUser = async (user) => {
    if (user.id) {
      user._id = new Realm.BSON.ObjectID();
      user._partition = realmApp.currentUser.id;
      user.id = realmApp.currentUser.id;
      try {
        debug('insertUser', { user })
        await collection.insertOne(user);
        setUserSaved(true);
      } catch (err) {
        if (err.error.match(/^Duplicate key error/)) {
          console.warn(`The following error means that we tried to insert multiple times`);
        }
        console.error(err);
      }
    }
  };

  const updateUserInfo = async (user, venmo, fullname) => {
    await collection.updateOne(
      { _id: user._id },
      { $set: { venmo, fullname } }
    );
  };

  return {
    user,
    setUser,
    currentUser,
    loading,
    userSaved,
    upsertUser,
    insertUser,
    updateUserInfo,
  };
}
