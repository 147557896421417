import React from "react";
import { Box } from "@mui/material";
import { PayPalButtons } from "@paypal/react-paypal-js";
// import { useOrders } from "../../../hooks/useOrders";
import { getItem } from "../../../shared/getEndpoint";
import { trackEvent } from "../../../shared/helpers";
//import { ApplePayButton } from "./ApplePayButton";

function CheckoutButtons({ item, setItem, currentUser }) {
  // const { saveOrder } = useOrders();
  const createOrderHandler = (item) => {
    // @ts-ignore
    return async (data, actions) => {
      const { paymentSource } = data;
      await currentUser.callFunction("getProfile", { test: 1 }); // to ensure valid token
      trackEvent({ action: "begin_checkout" });
      const items = [{ id: `${item._id}`, qty: "1" }];
      return currentUser.callFunction("paypalCreateOrder", { sshost: window.location.host, items, paymentSource })
        .then(({ id }) => id)
        .catch((e) => console.log("paypalCreateOrder ERR", e));
    };
  };

  const onApproveHandler = (item) => {
    return async (data, actions) => { // CHECKOUT.ORDER.APPROVED
      const docs = await getItem(item._id);
      const status = docs[0].status;
      if (status !== "active") {
        setItem({ ...docs[0] });
        return false;
      }

      const items = [{ id: `{item._id}`, _partition: item._partition, storeId: item.storeId }];

      const payload = {
        orderId: data.orderID,
        sshost: window.location.host,
        items,
        payerId: currentUser.id,
        payerProviderType: currentUser.providerType
      };
      return currentUser.callFunction("paypalCaptureOrder", payload)
        .then(async (res) => {
          const details = res.result;
          console.log("order.capture", { item }, { details }, { data });
          item.status = "sold";
          // if (item.inventoryCount) {
          //   item.inventoryCount = item.inventoryCount - 1;
          // }
          try {
            // await saveOrder({ item, payment: details, data }); // legacy save
            item.store[0].categories = []; // remove extraneous data from Listing
            // const userProviderType = currentUser.providerType;
            // const payment = { 
            //   id: details.id, 
            //   purchase_units: 
            //   details.purchase_units, 
            //   payer:details.payer,
            //   create_time: details.create_time 
            // };
            // const _data = { orderID: data.orderID, paymentSource: data.paymentSource };
            // await currentUser.callFunction("completePaypalOrder", { item, payment, data: _data, userProviderType });
            // update UI
            setItem({ ...item });
            trackEvent({
              action: "purchase",
              params: { value: item.price, currency: "USD", name: item.title },
            });
          } catch (ex) {
            console.error(ex);
          }


        })
        .catch((e) => console.log("paypalCaptureOrder ERR", e));
      // return actions.order.capture().then(async (details) => { // CHECKOUT.ORDER.COMPLETED

      // });
    };
  };

  return (
    <Box sx={{ px: 2 }}>
      <>
        {/* <ApplePayButton currentUser={currentUser} item={item} setItem={setItem} /> */}
        <PayPalButtons
          disabled={item.status === "sold"}
          createOrder={createOrderHandler(item)}
          onApprove={onApproveHandler(item)}
          onError={(err) => {
            trackEvent({ action: "buy_error" });
            console.log("PayPalButtons", err.message);
            alert("PayPal Checkout error: " + err.message);
          }}
        />
      </>
    </Box>
  );
}

export default CheckoutButtons;
