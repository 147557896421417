import React from "react";
import { Box, Container, Stack } from "@mui/material";
import { Link, useParams } from "react-router-dom";
import { useAxiosEndpoint } from "../../hooks/useAxiosEndpoint";

export function Page({ store }) {
    const { slug } = useParams();
    const queryParams = { slug };
    const { data, loading, error } = useAxiosEndpoint('page', { queryParams, useCache: true });

    React.useEffect(() => {
        const page = data?.documents[0]
        if (page?.title) {
            document.title = `${page.title} - ${store.name}`;
        }
    }, [data, store])

    return (
        <main>
            <Box sx={{ bgcolor: 'background.paper', py: 6 }}>
                <Container maxWidth="sm">
                    {!loading ?
                        <div dangerouslySetInnerHTML={{ __html: `<iframe title='${data?.documents[0].title}' frameborder='0' height='550' width='100%' src='${data?.documents[0].link}?embedded=true' />` }} />
                        : "Loading..."}
                    {error && "Page Not Found"}
                    <ul>
                        <li><Link to={"/"}>Home</Link></li>
                    </ul>
                    <Stack
                        sx={{ pt: 4 }}
                        direction="row"
                        spacing={2}
                        justifyContent="center">

                    </Stack>
                </Container>
            </Box>
        </main>
    );
}