import React from "react";
import { Container, LinearProgress } from "@mui/material";
import { useUsers } from "../../../hooks/useUsers";
import ProfileForm from "./ProfileForm";
import { SellerSettings } from "./SellerSettings";
import { useDocumentTitle } from "../../../hooks/useDocumentTitle";


export function ProfilePage({ store, currentUser }) {
  const { upsertUser, user, loading } = useUsers();

  useDocumentTitle('Profile');

  return (
    <Container maxWidth="sm" disableGutters={true} className="main-container">
      {loading ? <LinearProgress /> :
        <>
          <ProfileForm
            user={user}
            store={store}
            currentUser={currentUser}
            upsertUser={upsertUser}
          />
          <SellerSettings
            upsertUser={upsertUser}
            store={store}
            user={user}
            currentUser={currentUser} />
        </>}
    </Container>)
}
