import { Box, Container } from "@mui/material";
import { FacebookEmbed } from "react-social-media-embed";

// https://www.facebook.com/brad.oyler/posts/pfbid02yY7k5p81qF8QDSiSEDRjDRSRncjnqbWCuCZ4ffWZSYAA6shcAFQcKcwNPq73bzpBl
// https://www.facebook.com/groups/360662991534184/posts/1435666250700514/
export function SocialPosts(params) {
    return (
        <main>
            <Box sx={{ bgcolor: 'background.paper', py: 6 }}>
                <Container maxWidth="sm">
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <FacebookEmbed url="https://www.facebook.com/ReadersDigest/posts/pfbid0NQatZrvxg4nBrjk5ZTXkTVvU8XxuVdNkocRZEW8BhWbzyk28o7mKRq6Zi876Uc3Gl" width={550} />
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <FacebookEmbed url="https://www.facebook.com/andrewismusic/posts/451971596293956" width={550} />
                    </div>
                </Container>
            </Box>
        </main>
    )
}