import React from "react";
import Uploady, { UPLOADER_EVENTS } from "@rpldy/uploady";
import { getUploadURL } from "../../../shared/getEndpoint";
import { useListing } from "../../../hooks/useListing";
import { UploadButton } from "../../controls/UploadButton";
import { CircularProgress } from "@mui/material";

export function AddImage({ item, currentUser, imgUrls, setImgUrls }) {
    const { updateListingPart } = useListing();
    const [uploading, setUploading] = React.useState(false);
   
    const resFormatter = React.useCallback(async (res, status, headers) => {
        const response = JSON.parse(res);
        console.log(status, "# img URL:", response.result, item);
        const field = `imageUrl${imgUrls.length + 1}`;
        const url = `https://imagedelivery.net/JsOwOQEQsyMK6pDicrj7_w/${response.result.id}`
        await updateListingPart(item._id, { [field]: url });
        setImgUrls((prev) => [...prev, url]);
        return `${status} - OK!`;
    }, [updateListingPart, item, imgUrls, setImgUrls]);

    const listeners = React.useMemo(() => ({
        [UPLOADER_EVENTS.REQUEST_PRE_SEND]: async (context) => {
            const data = await getUploadURL({ token: currentUser.accessToken });
            context.options.destination = { url: data.result.uploadURL };
            console.log('REQUEST_PRE_SEND', context, imgUrls);
            return context;
        },
        [UPLOADER_EVENTS.ITEM_START]: (i) => {
            setUploading(true);
            console.log(`ITEM_START - ${i.id} : ${i.file.name}`);
        },
        [UPLOADER_EVENTS.ITEM_FINISH]: (i) => {
            setUploading(false);
            console.log(`ITEM_FINISH - ${i.id} : ${i.file.name}`, imgUrls.length);
        },
    }), [currentUser, imgUrls]);

    return (
        <>
            {imgUrls.length ?
                <Uploady
                    multiple={false}
                    // grouped
                    // maxGroupSize={2}
                    listeners={listeners}
                    method="POST"
                    formatServerResponse={resFormatter}
                >
                    <UploadButton imgCount={imgUrls.length} />
                    {uploading && <CircularProgress />}
                </Uploady> : ""}
        </>
    )


}