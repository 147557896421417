import React from "react";
import { Box, Card, InputAdornment, TextField, useMediaQuery, useTheme } from "@mui/material";
import { Search } from "@mui/icons-material";
import { useSearchParams } from "react-router-dom";


export function SearchBar({ setShowSearch }) {
  const [searchParams] = useSearchParams();
  const [value] = React.useState(searchParams.get('q'));

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const dialogTitleStyle = { minWidth: 320 }
  if (!fullScreen) {
    dialogTitleStyle.minWidth = 520
  }
  function handleClick() {
    setShowSearch(true);
  }

  return (
    <>
      <Card raised={false} sx={{ borderRadius: 5 }}>
        <Box sx={{ mt: 1 }} component="form" noValidate autoComplete="off">
          <TextField style={{ display: 'flex' }}
            id="outlined-search"
            label="Search an item..."
            type="search"
            variant="outlined"
            defaultValue={value}
            onClick={handleClick}
            InputProps={{
              sx: { borderRadius: 5 },
              endAdornment: (
                <InputAdornment position="end">
                  <Search />
                </InputAdornment>
              ),
            }}
          />
        </Box>
      </Card>
    </>

  )
}