import React from "react";
import { useCollection } from "./useCollection";
import { useRealmApp } from "../components/RealmApp";
import Config from "../shared/Config";
import {
  addValueAtIndex,
  // updateValueAtIndex,
  getItemIndex,
} from "../shared/helpers";

const { dataSourceName } = Config();

export function useListings({ store, activeListings, category = '' }) {
  const realmApp = useRealmApp();
  const [listings, setListings] = React.useState([]);
  const [loading, setLoading] = React.useState(true);

  const listingCollection = useCollection({
    cluster: dataSourceName,
    db: "ecommdb",
    collection: "listings",
  });

  React.useEffect(() => {
    const pipeline = [{
      $lookup:
      {
        from: "users",
        localField: "_partition",
        foreignField: "id",
        as: "seller"
      }
    },
    { "$match": { "storeId": store._id } },
  ];

  if(activeListings) {
    // @ts-ignore
    pipeline.push({ "$match": { "status": { "$in": ["active"] } } });
  } else {
     // @ts-ignore
    pipeline.push({ "$match": { "status": { "$in": ["sold", "archived"] } } })
  }

  // append category filter
  if (category) {
    // @ts-ignore
    pipeline.push({ "$match": { "category": category } })
  }

    setLoading(true);
    listingCollection.aggregate(pipeline)
    .then((res) => {
      // console.log('useListings.js: mongo', { res })
      setListings(res.reverse());
      setLoading(false);
    });
  }, [listingCollection, store, category, activeListings]);

  function addItem(listing) {
    setListings((prev) => {
      const idx = getItemIndex(prev, listing) ?? prev.length;
      if (idx === prev.length) {
        // insert at top of list
        return addValueAtIndex(prev, 0, listing);
      } else {
        return prev;
      }
    });
  }

  const insertListing = async (draftListing) => {
    if (draftListing.imageUrl) {
      draftListing._partition = realmApp.currentUser.id;
      try {
        await listingCollection.insertOne(draftListing);
        addItem(draftListing);
      } catch (err) {
        if (err.error.match(/^Duplicate key error/)) {
          console.warn(
            `The following error means that we tried to insert same _id multiple times. You may want to debounce the save input or implement an additional loading state to avoid sending the request in the first place.`
          );
        }
        console.error(err);
      }
    }
  };

  const updateListing = async (listing) => {
    // console.log({ listing })
    try {
      await listingCollection.updateOne(
        { _id: listing._id },
        { $set: listing }
      );
    } catch (err) {
      if (err.error.match(/^Duplicate key error/)) {
        console.warn(
          `The following error means that we tried to insert a todo multiple times (i.e. an existing todo has the same _id). In this app we just catch the error and move on. In your app, you might want to debounce the save input or implement an additional loading state to avoid sending the request in the first place.`
        );
      }
      console.error(err);
    }
  };

  return {
    loading,
    listings,
    insertListing,
    updateListing
  };
}
