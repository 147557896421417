import React from "react";
import {
  Container,
  Typography,
  List,
  LinearProgress
} from "@mui/material";
import { useShowLoader } from "../../hooks/util-hooks";
import { useMyOrders } from "../../hooks/useMyOrders";
import { OrdersTable } from "../lists/OrdersTable";
import { useDocumentTitle } from "../../hooks/useDocumentTitle";

export function Sales({store, currentUser}) {
  const { loading, sales } = useMyOrders();
  const showLoader = useShowLoader(loading, 200);
  useDocumentTitle('My Sales');

  return (
    <Container disableGutters={true} maxWidth="sm">
      {loading ? (
        showLoader ? (
          <LinearProgress />
        ) : <div />
      ) : (
        <div style={{marginTop:5}}>
          <div>
            <Typography style={{marginLeft:10}} variant="h6">
              {`${sales.length} sale${sales.length === 1 ? "" : "s"
                }`}
            </Typography>
          </div>
          <List style={{ width: "100%" }}>
            <OrdersTable isSales={true} currentUser={currentUser} store={store} items={sales} />
          </List>
        </div>
      )}
    </Container>
  );
}
