/// <reference path="../typedefs.js" />
import React from "react";
import { useCollection } from "./useCollection";
import { useRealmApp } from "../components/RealmApp";
import Config from "../shared/Config";

const { dataSourceName } = Config();

export function useMyOrders() {
  const realmApp = useRealmApp();
  /** @type {useState<Order[]>} */
  const [orders, setOrders] = React.useState([]);
  /** @type {useState<Order[]>} */
  let [sales, setSales] = React.useState([]);
  const [loading, setLoading] = React.useState(true);

  const collection = useCollection({
    cluster: dataSourceName,
    db: "ecommdb",
    collection: "orders",
  });

  React.useEffect(() => {
    collection.aggregate([
      {
        $lookup:
          {
            from: "stores",
            localField: "storeId",
            foreignField: "id",
            as: "stores"
          }
      },
      { $sort: {'created_ts': -1}},
      { $limit : 300 }
    ]).then((res) => {
      const sales = res.filter(s => String(s.sellerId) === realmApp.currentUser.id);
      const orders = res.filter(s => String(s.payerId) === realmApp.currentUser.id);
      // console.log('useMyOrders.js: mongo', {sales}, {orders});
      setOrders(orders);
      setSales(sales);
      setLoading(false);
    });
  }, [collection, realmApp.currentUser]);

  return {
    loading,
    sales,
    orders
  };
}
