import React from "react";
import Debug from "debug";
import { Dialog, DialogContent, DialogTitle, IconButton, InputAdornment, LinearProgress, List, TextField, useMediaQuery, useTheme } from "@mui/material";
import { CloseRounded, Search } from "@mui/icons-material";
import Autocomplete from '@mui/material/Autocomplete';
import { useSearchParams } from "react-router-dom";
import { SearchItems } from "../../shared/getEndpoint";
import { SearchItemCard } from "./SearchItemCard";
import { useDebounce } from "../../hooks/useDebounce";
import { trackEvent } from "../../shared/helpers";

const debug = Debug("SearchBar");
const CACHE = {}

export function SearchDialog({ store, setShowSearch, open }) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchItems, setSearchItems] = React.useState([]);
  const [loadingSearch, setSearchLoading] = React.useState(false);
  const [searchActive, setSearchActive] = React.useState(false);
  const [value, setValue] = React.useState(searchParams.get('q'));
  const debouncedValue = useDebounce(value, 100); // was 300

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const dialogTitleStyle = { minWidth: 320 }
  if (!fullScreen) {
    dialogTitleStyle.minWidth = 520
  }

  function handleClose(value) {
    setShowSearch(false);
  }

  const search = React.useCallback(async () => {
    if (!debouncedValue) {
      return;
    }
    setSearchLoading(true);
    let data;
    const cacheKey = `search_${debouncedValue}_${new Date().getHours()}`; // 1hr TTL
    if (CACHE[cacheKey]) {
      debug('CACHE hit', cacheKey);
      data = CACHE[cacheKey];
    } else {
      data = await SearchItems(debouncedValue, store._id);
      debug(`got /search?q=${debouncedValue}`, { data });
      if (debouncedValue?.length > 3) {
        trackEvent({ action: 'search', params: { search_term: debouncedValue } });
      }
      CACHE[cacheKey] = data;
    }
    setSearchLoading(false);
    setSearchItems(data);
  }, [debouncedValue, store]);

  React.useEffect(() => {
    search();
  }, [debouncedValue, search]);

  const handleInputChange = async (e, input) => {
    const len = input.length
    if (input === "") {
      debug('clear input', input);
      setSearchLoading(false);
      setSearchActive(false);
      setSearchItems([]);
    }
    if (len > 2) { // && !(len % 2)
      setSearchActive(true);
      setSearchLoading(true);
      debug('inputChange:', input)
      setValue(input);
      setSearchParams({ q: input })
    }
  };

  return (
    <>
      <Dialog fullScreen={fullScreen} onClose={handleClose} open={open}>
        <DialogTitle sx={dialogTitleStyle}>
          <IconButton style={{ float: 'right' }} onClick={() => setShowSearch(false)}>
            <CloseRounded />
          </IconButton>
          <Autocomplete
            freeSolo
            id="free-solo-search"
            disableClearable
            onInputChange={handleInputChange}
            options={store.categories.map((cat) => cat.name)}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Search..."
                InputProps={{
                  sx: { borderRadius: 6 },
                  endAdornment: (
                    <InputAdornment position="end">
                      <Search />
                    </InputAdornment>
                  ),
                  ...params.InputProps,
                  type: 'search',
                }}
              />
            )}
          />
          {/* <TextField sx={{ mt:1, display: 'flex' }}
            id="outlined-search"
            label="Search an item..."
            type="search"
            variant="outlined"
            defaultValue={value}
            //autoFocus={true}
            //focused={true}
            onChange={handleInputChange}
            InputProps={{
              sx: { borderRadius: 6 },
              endAdornment: (
                <InputAdornment position="end">
                  <Search />
                </InputAdornment>
              ),
            }}
          /> */}
        </DialogTitle>
        <DialogContent>
          {(searchItems?.length > 0) &&
            <List style={{ width: "100%" }}>
              {searchItems.map((item) => (
                <SearchItemCard
                  setShowSearch={setShowSearch}
                  key={String(item._id)}
                  item={item}
                />
              ))}
            </List>
          }
          {!loadingSearch && searchActive && (searchItems?.length === 0) && <div>&nbsp; &nbsp; No search results</div>}
          {loadingSearch && <LinearProgress sx={{ mt: 1 }} />}
        </DialogContent>
      </Dialog>
    </>

  )
}