/// <reference path="../typedefs.js" />
import React from "react";
import { useCollection } from "./useCollection";
import { useRealmApp } from "../components/RealmApp";
import Config from "../shared/Config";

const { dataSourceName } = Config();

export function useSalesTotals(yearMonth) {
  const realmApp = useRealmApp();
  /** @type {useState<any[]>} */
  let [totals, setTotals] = React.useState([]);
  const [loading, setLoading] = React.useState(true);

  const collection = useCollection({
    cluster: dataSourceName,
    db: "ecommdb",
    collection: "orders",
  });

  React.useEffect(() => {
    function getDateRange(yearMonth) {
      let monthNum, yearNum;
      if (yearMonth) {
        monthNum = Number(yearMonth.split("-")[1]) - 1;
        yearNum = Number(yearMonth.split("-")[0]);
      }

      const startDate = new Date(yearNum, monthNum, 1);
      const endDate = new Date(yearNum, monthNum + 1, 0);
      return { startDate, endDate };
    }

    let { startDate, endDate } = getDateRange(yearMonth);
    const dateFilterStage = {
      $match: { created_ts: { $gte: startDate, $lt: endDate } },
    };

    let dateGroup = '%Y-%m';
    if (yearMonth) {
      // @ts-ignore
      dateGroup = '%Y-%m-%d';
    }

    const pipeline = [
      {
        $match: {
          "sellerId": {
            "$in": [
              realmApp.currentUser.id
            ]
          },
          "item.status": {"$in": ["packed", "sold"]}
        }
      },
      { $sort: { created_ts: -1 } },
      {
        $addFields: {
          salePrice: {
            $cond: [
              { $gt: ["$item.discountPrice", 0] }, // boolean expression
              "$item.discountPrice", // true case
              "$item.price", // false case
            ],
          },
          created_ts: {
            $cond: {
              if: {
                $eq: [
                  {
                    $type: "$created_ts",
                  },
                  "date",
                ],
              },
              then: "$created_ts",
              else: null,
            },
          },
        },
      },
      {
        $addFields: {
          __alias_0: {
            date: { $dateToString: { format: dateGroup, date: "$created_ts" } }
          },
        },
      },
      {
        $group: {
          _id: {
            __alias_0: "$__alias_0",
          },
          __alias_3: {
            $sum: 1,
          },
          __alias_4: {
            $sum: "$salePrice",
          },
        },
      },
      {
        $project: {
          _id: 0,
          __alias_0: "$_id.__alias_0",
          __alias_1: "$_id.__alias_1",
          __alias_2: 1,
          __alias_3: 1,
          __alias_4: 1,
        },
      },
      {
        $project: {
          group: "$__alias_0",
          value: "$__alias_2",
          // "group_series_0": "$__alias_1",
          count: "$__alias_3",
          sum: "$__alias_4",
          _id: 0,
        },
      },
      {
        $limit: 32,
      },
    ];
    
    if (yearMonth) {
      // @ts-ignore
      pipeline.unshift(dateFilterStage);
    }

    collection.aggregate(pipeline).then((res) => {
      const totals = res; //res.filter(s => String(s.sellerId) === realmApp.currentUser.id);
      // const orders = res.filter(s => String(s.payerId) === realmApp.currentUser.id);
      // console.log('useMyOrders.js: mongo', {sales}, {orders});
      // setOrders(orders);
      setTotals(totals);
      setLoading(false);
    });
  }, [collection, yearMonth, realmApp.currentUser]);

  return {
    loading,
    totals,
  };
}
