import { Card, CardMedia } from '@mui/material';
import React from 'react';
import { itemImageURL } from '../../../shared/helpers';

function Welcome({ store }) {

    return (
        <Card sx={{ my: 1 }}>
       
                {/* <Typography variant="body1">
                    Welcome to {store.name}! {store.welcomeText}
                </Typography> */}
         
            {store.welcomeImageUrl ?
            <CardMedia
                component="img"
                style={{ maxHeight: 380 }}
                image={itemImageURL(store.welcomeImageUrl)}
                title={"Welcome to " + store.name}
            /> : ""}
        </Card>
    );
}

export default Welcome;