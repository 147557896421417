import React from "react";
import {
  Container,
  Button,
  Typography,
  List,
  LinearProgress
} from "@mui/material";
import { Add as AddIcon } from "@mui/icons-material";
import { useListings } from "../../../hooks/useListings";
import { DraftItemCard } from "./DraftItemCard";
import { useDraftItems } from "../../../hooks/useDraftItems";
import { useShowLoader } from "../../../hooks/util-hooks";
import { useConsignors } from "../../../hooks/useConsignors";
import { filterOptions } from "../../../shared/helpers";
import { ItemsTable } from "../../lists/ItemsTable";
import { useDocumentTitle } from "../../../hooks/useDocumentTitle";
import { Link } from "react-router-dom";

export function ListingsPage({ store, currentUser, activeListings = false }) {
  const listingCategory = sessionStorage.getItem('listings:category') || '';
  const [category, setCategory] = React.useState(listingCategory);
  const { loading, listings, ...listingActions } = useListings({ store, category, activeListings });
  const { items: consignors } = useConsignors();
  const { draftItems, ...draftActions } = useDraftItems();
  const showLoader = useShowLoader(loading, 200);
  const sizes = filterOptions(store.options, 'size');
  const { categories } = store;

  useDocumentTitle('Seller Listings');

  return (
    <Container disableGutters={true} maxWidth="sm">
      {loading ? (
        showLoader ? (
          <LinearProgress />
        ) : <div />
      ) : (
        <div>
          <div>
            <Button
              sx={{ mt: 1, ml: 1 }}
              disabled={false}
              size="medium"
              variant="contained"
              color="primary"
              endIcon={<AddIcon />}
              onClick={() => draftActions.createDraftItem(store)}
            >New Listing</Button>
            <Typography sx={{ ml: 3 }} component="span" variant="h6">
              {`${listings.length} ${activeListings ? 'active': 'inactive'} listing${listings.length === 1 ? "" : "s"
                }`}
            </Typography>
          </div>

          <List style={{ width: "100%" }}>
            {draftItems.map((draft) => (
              <DraftItemCard
                currentUser={currentUser}
                sizes={sizes}
                categories={categories}
                key={String(draft._id)}
                item={draft}
                consignors={consignors}
                listingActions={listingActions}
                draftActions={draftActions}
              />
            ))}
            <ItemsTable
              items={listings}
              categories={categories}
              category={category}
              setCategory={setCategory} />
          </List>
          {activeListings ?
            <Button component={Link} to={"/listings-inactive"} >View Inactive Listings</Button> :
            <Button component={Link} to={"/listings"} >View Active Listings</Button>
          }
        </div>
      )}
    </Container>
  );
}
