import React from "react";
import Image from "mui-image";
import { itemImageURL } from "../../shared/helpers";

/**
 * 
 * @param {object} props
 * @param {string} props.url
 * @param {number} [props.width]
 * @param {number} [props.height]
 * @param {string} [props.alt]
 * @param {boolean} [props.large]
 * @param {string} [props.loading]
 * @param {any} [props.imageSize]
 */
function ItemImage({ width, loading, height, url, large, imageSize, alt ='Product item' }) {
    let imgStyle = { width: '100%' };
    if (width) {
        imgStyle.width = `${width}px`;
    }

    return (
        <Image
            // @ts-ignore
            loading={loading}
            duration={100}
            shift="left"        
            style={imgStyle}
            height={height}
            alt={alt}
            src={itemImageURL(url , {imageSize, large})}
        />);
}

export default ItemImage;