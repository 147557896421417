import { Container, Typography } from "@mui/material";

export function ErrorPage({ error }) {
    return (
        <>
        {error &&
            <Container style={{ marginTop: 15 }} maxWidth="sm" className="main-container">
              <Typography variant="h4" sx={{ mt:3, textAlign: 'center' }}>Sorry...</Typography>
              <Typography variant={"h6"} sx={{ mt:3, textAlign: 'center' }}>{error.message} {error.status} </Typography>
            </Container>
        }
        </>
    )
}