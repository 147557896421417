import React from "react";
import { LoginDialog } from "../controls/LoginDialog";
import { HomePage } from "./Home/HomePage";
import { trackEvent } from "../../shared/helpers";

export function Login({ store, setShowSearch }) {
  const [showLogin, setShowLogin] = React.useState(true);
  
  React.useEffect(() => {
    trackEvent({ action: 'showlogin' });
  }, []);

  return (
    <span>
      <LoginDialog
        store={store}
        open={showLogin}
        setShowLogin={setShowLogin}
      />
      <HomePage store={store} setShowSearch={setShowSearch} />
    </span>
  );
}
